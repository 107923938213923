var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "js-common-wrap js-modify-personInfo" }, [
    _vm._m(0),
    _c("div", { staticClass: "js-common-content" }, [
      _c("ul", { staticClass: "form-box" }, [
        _c(
          "li",
          [
            _vm._m(1),
            _c("el-input", {
              attrs: {
                placeholder: "请输入原密码",
                "show-password": "",
                size: "small",
              },
              model: {
                value: _vm.oldPassword,
                callback: function ($$v) {
                  _vm.oldPassword = $$v
                },
                expression: "oldPassword",
              },
            }),
          ],
          1
        ),
        _c(
          "li",
          [
            _vm._m(2),
            _c("el-input", {
              attrs: {
                placeholder: "请输入新密码",
                "show-password": "",
                size: "small",
              },
              model: {
                value: _vm.newPassword,
                callback: function ($$v) {
                  _vm.newPassword = $$v
                },
                expression: "newPassword",
              },
            }),
          ],
          1
        ),
        _c(
          "li",
          [
            _vm._m(3),
            _c("el-input", {
              attrs: {
                placeholder: "请再次输入新密码",
                "show-password": "",
                size: "small",
              },
              model: {
                value: _vm.newPassword2,
                callback: function ($$v) {
                  _vm.newPassword2 = $$v
                },
                expression: "newPassword2",
              },
            }),
          ],
          1
        ),
        _c(
          "li",
          { staticClass: "submit" },
          [
            _c(
              "el-button",
              {
                attrs: { round: "", size: "large" },
                on: { click: _vm.cancel },
              },
              [_vm._v("取消")]
            ),
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "large" },
                on: { click: _vm.save },
              },
              [_vm._v("保存")]
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "js-common-head" }, [
      _c("label", [_vm._v("密码修改")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "common-tip" }, [
      _c("i", [_vm._v("*")]),
      _vm._v("原密码："),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "common-tip" }, [
      _c("i", [_vm._v("*")]),
      _vm._v("新密码："),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "common-tip" }, [
      _c("i", [_vm._v("*")]),
      _vm._v("确认密码："),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }