<template>
  <div class="js-common-wrap js-modify-personInfo">
    <div class="js-common-head">
      <label>密码修改</label>
        
    </div>
    <div class="js-common-content">
      <ul class="form-box">
        <li>
          <label class="common-tip"><i>*</i>原密码：</label>
          <el-input v-model="oldPassword" placeholder="请输入原密码" show-password size="small"></el-input>
        </li>
        <li>
          <label class="common-tip"><i>*</i>新密码：</label>
          <el-input v-model="newPassword" placeholder="请输入新密码" show-password size="small"></el-input>
        </li>
        <li>
          <label class="common-tip"><i>*</i>确认密码：</label>
          <el-input v-model="newPassword2" placeholder="请再次输入新密码" show-password size="small"></el-input>
        </li>
        <li class="submit">
          <el-button round size="large" @click="cancel">取消</el-button>
          <el-button type="primary" size="large" @click="save">保存</el-button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Jiashi10Modifypersoninfo',
  data() {
    return {
      oldPassword: '',
      newPassword: '',
      newPassword2: ''
    }
  },
  mounted() {},
  methods: {
    cancel(){
      this.$router.go(-1)
    },
    // 保存
    save(){
      if(this.newPassword !== this.newPassword2){
        this.$message({
          type: 'error',
          message: '新密码和确认密码不一致！',
          duration: 1000
        })
        return
      }
      this.$axios({
        method: 'post',
        url: `api/csp/mgr/v1/user/changePassword`,
        data:{
          data:{
            oldPassword: this.oldPassword,
            newPassword: this.newPassword
          }
        }
      }).then((response)=>{
        let data = response.data
        if(!data.status){
          this.exit()
        }else{
          this.$message({
            type: 'error',
            message: data.msg,
            duration: 1000
          })
        }
      })
    },
    exit(){
      this.$axios({
        method: 'post',
        url: 'api/csp/v1/tenant/user/logout'
      }).then((response)=>{
        let res = response.data
        if(!res.status){
          localStorage.setItem('eleToken','')
          this.$router.push({path: '/login'})
        }else{
          this.$message({
            type: 'error',
            message: res.msg,
            duration: 1000
          })
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/personCenter.scss';
.submit{
  display: flex;
  justify-content: center;
}
</style>